import {
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ShowEventAttendeeDetails } from "../ShowEventAttendeeDetails";
import { Some } from "../../../../utils/Some";
import { editCheckinCheckoutTime } from "../../../../constant/api";
import moment from "moment-timezone";

interface Props {
  readonly show: boolean;
  readonly notify: (message: string, status: string) => void;
  readonly handleClose: () => void;
  readonly classes: {
    readonly paper: string;
    readonly button: string;
    readonly info: string;
    readonly root: string;
    readonly table: string;
    readonly backdrop: string;
  };
  readonly eventAttendee: number[];
  readonly clearEventAttendeeIds: () => void;

  selectedAttendees: EventAttendee[];
  checkInTz: string | null;
  checkOutTz: string | null;
  checkInTime: string;
  checkOutTime: string;
}

const ERROR_MESSAGES = {
  checkInAfterCheckOut: "The Date and Time should be greater than the check in date.",
  futureDate: "The Date and Time should be less than the current date.",
  invalidDate: "Please select valid date.",
};

const EditCheckInCheckOutTimeModal = memo(
  ({
    show,
    handleClose,
    eventAttendee,
    notify,
    clearEventAttendeeIds,
    classes,
    selectedAttendees,
    checkOutTz,
    checkInTz,
    checkInTime: initialCheckInTime,
    checkOutTime: initialCheckOutTime,
  }: Props) => {
    const [checkInTime, setCheckInTime] = useState<string>(initialCheckInTime);
    const [checkOutTime, setCheckOutTime] = useState<string>(initialCheckOutTime);
    const [checkinError, setCheckinError] = useState<string>("");
    const [checkoutError, setCheckoutError] = useState<string>("");
    const [failedAttendees, setFailedAttendees] = useState<EventAttendee[]>([]);
    const [failedAttendeesError, setFailedAttendeesError] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);

    const saveCheckInCheckOutTime = async () => {
      if (checkinError.length > 0 || checkoutError.length > 0) {
        return;
      }
      setSubmitted(true);
      const eventAttendeeIds =
        failedAttendees.length > 0
          ? failedAttendees.map(({ eventAttendeeId }: EventAttendee) => eventAttendeeId)
          : eventAttendee;
      try {
        const params = {
          checkIn: Some(checkInTz) ? moment.tz(checkInTime, checkInTz).utc().format() : checkInTime,
          checkOut: Some(checkOutTz) ? moment.tz(checkOutTime, checkOutTz).utc().format() : checkOutTime,
          eventAttendeeIds,
        };
        const response = await editCheckinCheckoutTime(params);
        const failedAttendeeIds = response.data
          .filter(({ status }: CheckInUpdate) => status === "failed")
          .map(({ eventAttendeeId }: CheckInUpdate) => eventAttendeeId);

        const failedAttendees = selectedAttendees.filter(({ eventAttendeeId }: EventAttendee) =>
          failedAttendeeIds.includes(eventAttendeeId),
        );

        if (failedAttendees.length === 0) {
          notify("Edit checkin/checkout times successful", "success");
          handleClose();
          clearEventAttendeeIds();
        } else {
          setFailedAttendees(failedAttendees);
          setFailedAttendeesError("Failed to edit checkin/checkout times for below persons");
        }
      } catch (err) {
        notify("Edit checkin/checkout times failed", "failed");
        handleClose();
        clearEventAttendeeIds();
      } finally {
        setSubmitted(false);
      }
    };

    const isFutureDate = (tz: string | null, value: string) =>
      Some(tz) ? moment.tz(value, tz).isAfter(moment.tz(new Date(), tz)) : new Date(value) > new Date();

    // Managing time zones for a single event attendee check-in
    const handleCheckinTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!value) {
        setCheckInTime(value);
        setCheckinError(ERROR_MESSAGES.invalidDate);

        return;
      }

      if (isFutureDate(checkInTz, value)) {
        setCheckInTime(value);
        setCheckinError(ERROR_MESSAGES.futureDate);

        return;
      }

      setCheckInTime(value);
      setCheckinError("");
    };

    useEffect(() => {
      handleCheckoutTimeChange(checkOutTime);
    }, [checkInTime]);

    /**
     * Managing time zones for a single event attendee check-out
     * When we select or edit just one event attendee, this modal will receive the check-out timezone
     */
    const handleCheckoutTimeChange = (value: string) => {
      const checkInDate =
        selectedAttendees.length === 1 && Some(checkOutTz)
          ? moment.tz(checkInTime, checkInTz || selectedAttendees[0].checkInTz)
          : new Date(checkInTime);

      if (!value) {
        setCheckOutTime(value);
        setCheckoutError("Please select valid checkout date.");

        return;
      }

      if (isFutureDate(checkOutTz, value)) {
        setCheckOutTime(value);
        setCheckoutError(ERROR_MESSAGES.futureDate);

        return;
      }

      if (checkInDate > ((Some(checkOutTz) && moment.tz(value, checkOutTz)) || new Date(value))) {
        setCheckOutTime(value);
        setCheckoutError(ERROR_MESSAGES.checkInAfterCheckOut);

        return;
      }

      setCheckOutTime(value);
      setCheckoutError("");
    };

    const eventAttendees = failedAttendees.length > 0 ? failedAttendees : selectedAttendees;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={show}
        disableBackdropClick
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" />
            </Grid>
            <Grid item>
              <h4 className="modal-title">Edit Record Times</h4>
              <h6 className="modal_text">Check-In the selected people</h6>
            </Grid>
            <Grid item className="image_padding">
              <TextField
                id="datetime-local"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={checkInTime}
                onChange={handleCheckinTimeChange}
              />
            </Grid>
            <span className="error-msg highlight">{checkinError}</span>
            <Grid item style={{ marginTop: 25 }}>
              <h6 className="modal_text">Check-out the selected people</h6>
            </Grid>
            <Grid item className="image_padding">
              <TextField
                id="datetime-local"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={checkOutTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckoutTimeChange(e.target.value)}
              />
            </Grid>
            <span className="error-msg highlight">{checkoutError}</span>
            <Grid item className={classes.info} style={{ marginTop: 25 }}>
              <h6>
                <b>Note:</b> The check-in/check-out time should be in the activity&#39;s timezone.
              </h6>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className="common_buttons"
                style={{ float: "right", marginTop: "15px" }}
                onClick={saveCheckInCheckOutTime}
                disabled={checkinError.length > 0 || checkoutError.length > 0 || submitted}
              >
                Save
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 15 }}>
              <span className="error-msg highlight">{failedAttendeesError}</span>
            </Grid>
          </Grid>
          {eventAttendees.length !== 0 && (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="record_data">Full Name</TableCell>
                    <TableCell className="record_data">Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventAttendees.map(({ eventAttendeeId, firstName, lastName, email }: EventAttendee) => (
                    <ShowEventAttendeeDetails
                      key={eventAttendeeId.toString()}
                      eventAttendeeId={eventAttendeeId}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </Modal>
    );
  },
);

const styles = (theme: { spacing: { unit: number }; palette: { background: { paper: any } }; shadows: any[] }) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    info: {
      marginTop: 20,
      paddingLeft: 7,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 55,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });

export default withStyles(styles)(EditCheckInCheckOutTimeModal);
