import "./components/UI-Components/CommonComponents.css";
import React, { useEffect, useState } from "react";
import App from "./components/App";
import { AuthProvider } from "./components/AuthProvider";
import { DataDogProvider } from "./components/DataDogProvider";
import { DatadogErrorBoundary } from "./components/DatadogErrorBoundary";
import { IdleTimer } from "./IdleTimer";
import LoadingScreen from "./components/UI-Components/LoadingScreen";
import { None } from "./utils/None";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { getEnvironment } from "./components/Checkin-Web/constant/api";
import history from "./history";
import { useLaunchDarkly } from "./hooks/launchDarkly";

const root = document.getElementById("root");

function Index() {
  const [env, setEnv] = useState<string | undefined>(undefined);
  const { LDProvider, isLoading: isLaunchDarklyLoading } = useLaunchDarkly();

  useEffect(() => {
    async function fetchEnvironment() {
      const {
        data: { environment },
      } = await getEnvironment();

      setEnv(environment);
    }

    fetchEnvironment();
  }, []);

  if (None(LDProvider) || isLaunchDarklyLoading) {
    return <LoadingScreen minHeight="100vh" />;
  }

  return (
    <LDProvider>
      <AuthProvider>
        <DataDogProvider environment={env}>
          <DatadogErrorBoundary>
            <IdleTimer>
              <Router history={history}>
                <App />
              </Router>
            </IdleTimer>
          </DatadogErrorBoundary>
        </DataDogProvider>
      </AuthProvider>
    </LDProvider>
  );
}

if (root !== null) {
  ReactDOM.render(<Index />, root);
}
