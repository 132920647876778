/* eslint-disable no-unused-vars */
import { AxiosResponse } from "axios";
import { CheckoutAutomationType } from "../types/workflow";
import Cookies from "js-cookie";
import { IdToken } from "@auth0/auth0-react";
import { OrgInfo } from "../types/auth";
/* eslint-enable no-unused-vars */
import axios from "../utils/ApiInterceptor";
import { useStore } from "../store/store";

interface response<T> {
  data: {
    groups?: any;
    item: T[];
    success: boolean;
    length: number;
    map: any;
    metadata: {
      totalPages: number;
      totalRecords: number;
      pageSize?: number;
      total?: number;
    };
    templateData: T[];
    pagingInfo: {
      hasNextPage: boolean;
      after: string;
    };
  };
}

interface UpdateCheckInResponse<T> {
  data: T[];
}

interface EditCheckinCheckoutTimeResponse<T> {
  data: T[];
}

let getAccessTokenSilently: () => Promise<string>;
let getIdTokenClaims: () => Promise<IdToken | undefined>;

// Can't use getAccessTokenSilently (from useAuth0()) directly here as this file is not a functional
// component so passing the reference of getAccessTokenSilently as parameter and assigning that
// reference to global variable
export function setAccessTokenFetcher(fetcher: () => Promise<string>) {
  if (!getAccessTokenSilently) {
    getAccessTokenSilently = fetcher;
  }
}

// Can't use setIdTokenFetcher (from useAuth0()) directly here as this file is not a functional
// component so passing the reference of setIdTokenFetcher as parameter and assigning that
// reference to global variable
export function setIdTokenFetcher(fetcher: () => Promise<IdToken | undefined>) {
  if (!getIdTokenClaims) {
    getIdTokenClaims = fetcher;
  }
}

export async function getAuthHeader() {
  try {
    return { Authorization: `Bearer ${await getAccessTokenSilently()}`, id_token: (await getIdTokenClaims())?.__raw };
  } catch (error) {
    return {};
  }
}

export function getSelectedOrgId() {
  const selectedOrg = useStore.getState().selectedOrg;
  if (!selectedOrg) {
    throw new Error("No selected org");
  }

  return selectedOrg.id;
}

export async function getOrgTemplates() {
  const selectedOrgId = getSelectedOrgId();
  const url = `/api/org/${selectedOrgId}/getOrgMeritTemplates`;
  const { data: item } = await axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return item;
}

export async function getAllPolicies() {
  const selectedOrgId = getSelectedOrgId();
  const url = `/api/org/${selectedOrgId}/getOrgPolicies`;
  const { data: item } = await axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return item;
}

export async function getPolicies(orgId: string) {
  const url = `/api/org/${orgId}/getPolicies`;
  const { data: item } = await axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return item;
}

export async function getAdminOrgs() {
  const { data: orgs } = await await axios.get("/api/org/getOrgs", {
    headers: { ...(await getAuthHeader()) },
  });

  return orgs;
}

export async function webLoginSuccess(orgId: string): Promise<string> {
  const { data } = await axios.get(`/api/auth/${orgId}/web-login-success`, {
    headers: { ...(await getAuthHeader()) },
  });

  return data;
}

export async function getAllEvents(params: number, page?: number, isDraft?: number): Promise<response<EventList>> {
  const selectedOrgId = getSelectedOrgId();

  const draftQP = isDraft ? `${isDraft}` : "";
  const url =
    page === undefined
      ? `/api/event/${selectedOrgId}?publish=${params}&isDraft=${draftQP}&sortBy=name&orderBy=asc`
      : `/api/event/${selectedOrgId}?publish=${params}&page=${page}&isDraft=${draftQP}&sortBy=name&orderBy=asc`;
  const URL: Promise<response<EventList>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getAllActivities(): Promise<response<EventList>> {
  // Directly using the store here as this is a there are some places uses this getAllActivities
  // and not all are functional components
  // also getAllActivities are called in pages with authenticated user
  const selectedOrgId = getSelectedOrgId();

  const url = `/api/event/${selectedOrgId}/all`;

  const URL: Promise<response<EventList>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function searchEvents(
  searchText: string,
  params: number,
  page: number,
  isDraft: number,
): Promise<response<EventList>> {
  const selectedOrgId = getSelectedOrgId();
  const URL: Promise<response<EventList>> = axios.get(
    `/api/event/${selectedOrgId}?text=${searchText}&publish=${params}&isDraft=${isDraft}&page=${page}`,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}
export async function getAllQualifications(page?: number): Promise<response<QualificationObject>> {
  const url =
    page === undefined
      ? "/api/qualification/get-qualifications?sortBy=name&orderBy=asc"
      : `/api/qualification/get-qualifications?page=${page}&sortBy=name&orderBy=asc`;
  const URL: Promise<response<QualificationObject>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function createEvent(params: {}): Promise<response<EventList>> {
  const selectedOrgId = getSelectedOrgId();

  const url = `/api/event/${selectedOrgId}`;

  const URL: Promise<response<EventList>> = axios.post(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function setEventPublishStatus(eventId: number, isPublished: boolean) {
  const selectedOrgId = getSelectedOrgId();

  await axios.post(
    `/api/event/${selectedOrgId}/publish`,
    {
      eventId,
      isPublished,
    },
    { headers: { ...(await getAuthHeader()) } },
  );
}

export async function updateEvent(params: {}): Promise<response<EventList>> {
  const selectedOrgId = getSelectedOrgId();

  const url = `/api/event/${selectedOrgId}/update`;

  const URL: Promise<response<EventList>> = axios.post(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function checkIn(params: {}): Promise<response<{}>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<{}>> = axios.post(`/api/event-attendee/${orgId}/checkin`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function bulkCheckIns(params: {}): Promise<response<BulkCheckinResponse>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<BulkCheckinResponse>> = axios.post(`/api/event-attendee/${orgId}/bulk-checkins`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function fetchBulkCheckins(page: number): Promise<response<CheckinBulk>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<CheckinBulk>> = axios.get(`/api/event-attendee/${orgId}/bulk-checkins?page=${page}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function fetchBulkCheckin(bulkUploadId: string, status: string): Promise<response<CheckinBulkDetails>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<CheckinBulkDetails>> = axios.get(
    `/api/event-attendee/${orgId}/bulk-checkin/${bulkUploadId}?status=${status}`,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

export async function exportBulkCheckIns(bulkUploadId: string, status: string): Promise<response<Blob>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<Blob>> = axios.get(
    `/api/event-attendee/${orgId}/bulk-checkin/export?bulkUploadId=${bulkUploadId}&status=${status}`,
    {
      headers: { ...(await getAuthHeader()) },
      responseType: "blob",
    },
  );
  const response = await URL;

  return response;
}

export async function checkOut(params: {}): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.post("/api/event-attendee/checkOut", params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function manualCheckOut(params: {}): Promise<response<{}>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<{}>> = axios.post(`/api/event-attendee/${orgId}/manual-checkout`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function addNotes(params: {}): Promise<response<{}>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<{}>> = axios.post(`/api/event-attendee/${orgId}/add-note`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function saveWaiver(params: {}): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.post("/api/waiver/save-waiver", params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function getWaivers(page?: number): Promise<response<WaiverList>> {
  const selectedOrgId = getSelectedOrgId();
  const url =
    page === undefined
      ? `/api/waiver/${selectedOrgId}/get-waivers?sortBy=name&orderBy=asc`
      : `/api/waiver/${selectedOrgId}/get-waivers?page=${page}&sortBy=name&orderBy=asc`;
  const URL: Promise<response<WaiverList>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function getWaiverTags(): Promise<response<WaiverTags>> {
  const URL: Promise<response<WaiverTags>> = axios.get("/api/waiver/get-waivers-tags", {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function getWaiverContent(params: string): Promise<response<waiverData>> {
  const URL: Promise<response<waiverData>> = axios.get(`/api/waiver/get-waiver?waiverId=${params}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function searchWaiver(searchText: string, page: number): Promise<response<WaiverList>> {
  const URL: Promise<response<WaiverList>> = axios.get(`/api/waiver/get-waivers?text=${searchText}&page=${page}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}
export async function searchQualification(searchText: string, page: number): Promise<response<Qualifications>> {
  const URL: Promise<response<Qualifications>> = axios.get(
    `/api/qualification/get-qualifications?text=${searchText}&page=${page}`,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}
export async function updateWaiver(params: {}): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.post("/api/waiver/update-waiver", params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getOrganizations(): Promise<response<OrgInfo[]>> {
  const URL: Promise<response<OrgInfo[]>> = axios.get("/api/org/getOrgs", {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getOrganizationDetail(): Promise<response<orgDetails>> {
  const { selectedOrg } = useStore.getState();
  const orgId = selectedOrg?.id || JSON.parse(Cookies.get("virtualKioskIdentification") ?? "{}").sigmaOrgId;

  if (!orgId) {
    throw new Error("No selected org");
  }

  const URL: Promise<response<orgDetails>> = axios.get(`/api/org/${orgId}/getOrgInfo`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

/**
 * @deprecated This API has been removed as part of the code cleanup for the Sigma client [RN-1077].
 */
export async function getOrgMeritTemplates(orgId = "", nextPage = ""): Promise<response<MeritTemplate>> {
  const URL: Promise<response<MeritTemplate>> = axios.get(
    `/api/org/${orgId}/getOrgMerit-templates?selectedOrgId=${orgId}&nextPage=${nextPage}`,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

/**
 * @deprecated This API has been removed as part of the code cleanup for the Sigma client [RN-1077].
 */
export async function getOrgSearch(params: string): Promise<response<orgDetails>> {
  const URL: Promise<response<orgDetails>> = axios.get(`/api/org/search-org?text=${params}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function createQualification(params: {}): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.post("/api/qualification/save-qualification", params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function updateQualification(params: {}): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.post("/api/qualification/update-qualification", params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getQualificationById(params: number): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.get(`/api/qualification/get-qualification?qualificationId=${params}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function searchEventAttendee(params: {}): Promise<response<EventAttendee>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<any>> = axios.post(`/api/event-attendee/${orgId}`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function updateCheckinTime(params: {}): Promise<UpdateCheckInResponse<CheckInUpdate>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<UpdateCheckInResponse<CheckInUpdate>> = axios.put(`/api/event-attendee/${orgId}/checkin`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function editCheckinCheckoutTime(params: {}): Promise<
  EditCheckinCheckoutTimeResponse<EditCheckinCheckout>
> {
  const orgId = getSelectedOrgId();
  const URL: Promise<EditCheckinCheckoutTimeResponse<EditCheckinCheckout>> = axios.put(
    `/api/event-attendee/${orgId}/checkin-checkout`,
    params,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

export async function getSnapShotDetails(
  orgId: string,
  params: {
    readonly eventIds: number[];
    readonly startDate: string | undefined;
    readonly endDate: string | undefined;
  },
): Promise<response<SnapShotData>> {
  const URL: Promise<response<SnapShotData>> = axios.post(`/api/dashboard/${orgId}/snapshot`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function importCheckIns(params: {
  readonly eventIds: Array<number>;
  readonly fromDate: string | undefined;
  readonly toDate: string | undefined;
}): Promise<response<Blob>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<Blob>> = axios.post(`/api/dashboard/${orgId}/export`, params, {
    headers: { ...(await getAuthHeader()) },
    responseType: "blob",
  });
  const response = await URL;

  return response;
}

export async function exportCheckIns(params: {
  readonly eventId: string[];
  readonly fromDate: string | undefined;
  readonly toDate: string | undefined;
}): Promise<response<Blob>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<Blob>> = axios.get(`/api/event-attendee/${orgId}/export?eventId=${params.eventId}`, {
    headers: { ...(await getAuthHeader()) },
    responseType: "blob",
  });
  const response = await URL;

  return response;
}

export async function exportOrphanedCheckouts(selectedOrphanedCheckOutIds: number[] = []) {
  const orgId = getSelectedOrgId();

  const URL = await axios({
    data: {
      orphanedCheckoutIds: selectedOrphanedCheckOutIds,
    },
    headers: { ...(await getAuthHeader()) },
    method: "POST",
    responseType: "blob",
    url: `/api/orphaned-checkout/${orgId}/export`,
  });
  const response = await URL;

  return response;
}

export async function getBreakDownData(params: {
  readonly endDate: string | undefined;
  readonly eventIds: number[];
  readonly startDate: string | undefined;
}): Promise<response<BreakdownData>> {
  const selectedOrgId = getSelectedOrgId();
  const URL: Promise<response<BreakdownData>> = axios.post(`/api/dashboard/${selectedOrgId}/breakdowns`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function deleteCheckinLog(params: {
  readonly eventAttendeeId: number[];
}): Promise<DeleteCheckinLogResponse> {
  const orgId = getSelectedOrgId();

  const URL: Promise<DeleteCheckinLogResponse> = axios.post(`/api/event-attendee/${orgId}/delete`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getAllKioskEvents(): Promise<response<EventList>> {
  const url = "/api/kiosk/events";

  const URL: Promise<response<EventList>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function updateKioskEvents(params: {
  readonly eventId: number[];
  readonly name: string;
}): Promise<response<VirtualKiosk>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<VirtualKiosk>> = axios.post(`/api/virtual-kiosk/${orgId}`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getKioskEvents(kioskId: number): Promise<response<EventList>> {
  const orgId = getSelectedOrgId();

  const url = `/api/virtual-kiosk/events/${orgId}?kioskId=${kioskId}`;
  const URL: Promise<response<EventList>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getVirtualKioskEvents(
  active: number,
  page: number,
  eventName?: string,
): Promise<response<VirtualKiosk>> {
  const orgId = getSelectedOrgId();

  let url;
  if (eventName) {
    url = `/api/virtual-kiosk/${orgId}?active=${active}&page=${page}&name=${eventName}`;
  } else {
    url = `/api/virtual-kiosk/${orgId}?active=${active}&page=${page}`;
  }
  const URL: Promise<response<VirtualKiosk>> = axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function updateKioskEvent(params: {
  readonly active: boolean;
  readonly eventId: number | number[];
  readonly kioskId: number;
}): Promise<response<VirtualKiosk>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<VirtualKiosk>> = axios.put(
    `/api/virtual-kiosk/kiosk-event/${orgId}?kioskId=${params.kioskId}`,
    params,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

export async function updateVirtualKiosk(params: {
  readonly active?: boolean;
  readonly kioskId: number;
  readonly name?: string;
}): Promise<response<{}>> {
  const orgId = getSelectedOrgId();

  const url = `/api/virtual-kiosk/${orgId}?kioskId=${params.kioskId}`;
  const URL: Promise<response<{}>> = axios.put(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function checkVirtualKioskName(params: {
  readonly name: string;
}): Promise<response<VirtualKioskNameCheck>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<VirtualKioskNameCheck>> = axios.post(
    `/api/virtual-kiosk/duplicate-check/${orgId}`,
    params,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

export async function editCheckinLogName(params: {
  readonly eventAttendeeId: number;
  readonly firstName: string;
  readonly lastName: string;
}): Promise<response<{}>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<{ status: string }>> = axios.put(`/api/event-attendee/${orgId}/change-name`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function logOut(): Promise<response<string>> {
  const URL: Promise<response<string>> = axios.get("/api/auth/logout", {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function refreshToken(): Promise<response<void>> {
  const URL: Promise<response<void>> = axios.get("/api/auth/refresh-token", {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function fetchSessions(page: number): Promise<response<SessionType>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<SessionType>> = axios.get(`/api/session/${orgId}?page=${page}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function deleteOfflineLog(sessionId: string): Promise<response<void>> {
  const orgId = getSelectedOrgId();
  const url = `/api/session/${orgId}/${sessionId}`;
  const URL: Promise<response<void>> = axios.delete(url, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function downloadOfflineLog(sessionId: string): Promise<AxiosResponse<any, any>> {
  const orgId = getSelectedOrgId();
  const response = await axios({
    headers: { ...(await getAuthHeader()) },
    method: "GET",
    responseType: "blob",
    url: `/api/session/${orgId}/${sessionId}`,
  });

  return response;
}

export async function fetchOrphanedCheckOuts(page: number): Promise<response<OrphanedCheckoutType>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<OrphanedCheckoutType>> = axios.get(`/api/orphaned-checkout/${orgId}?page=${page}`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function deleteOrphanedCheckout(params: {
  readonly orphanedCheckoutIds: number[];
}): Promise<response<void>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<void>> = axios.post(`/api/orphaned-checkout/${orgId}/delete`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getMatchedCheckouts(sessionId: string): Promise<response<MatchOfflineCheckoutType>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<MatchOfflineCheckoutType>> = axios.get(`/api/session/${orgId}/${sessionId}/match`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function approveMatchedCheckout(
  sessionId: string,
  params: {
    readonly checkoutSessionLogId: string;
    readonly eventAttendeeId: number;
  },
): Promise<response<void>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<void>> = axios.post(`/api/session/${orgId}/${sessionId}/match/approve`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function rejectMatchedCheckout(
  sessionId: string,
  params: {
    readonly checkoutSessionLogId: string;
  },
): Promise<response<void>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<void>> = axios.post(`/api/session/${orgId}/${sessionId}/match/reject`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function fetchUnMatchedCheckouts(sessionId: string): Promise<response<NoMatchOfflineCheckoutType>> {
  const orgId = getSelectedOrgId();

  const URL: Promise<response<NoMatchOfflineCheckoutType>> = axios.get(`/api/session/${orgId}/${sessionId}/no-match`, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function deleteUnMatchedCheckout(
  sessionId: string,
  logId: string,
  params: { readonly notes: string },
): Promise<response<void>> {
  const orgId = getSelectedOrgId();
  const url = `/api/session/${orgId}/${sessionId}/no-match/${logId}`;
  const URL: Promise<response<void>> = axios.post(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getMatchesForCheckinLog(eventAttendeeId: string): Promise<response<MatchForCheckinLog>> {
  const orgId = getSelectedOrgId();
  const URL: Promise<response<MatchForCheckinLog>> = axios.get(
    `/api/event-attendee/${orgId}/${eventAttendeeId}/match`,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

export async function createCheckOutAutomation(params: CreateCheckOutAutomationRequestParam) {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}`;
  await axios.post(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
}

export async function getCheckOutAutomations(): Promise<response<CheckOutAutomation>> {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}`;
  const response: response<CheckOutAutomation> = await axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return response;
}

export async function getCheckOutAutomationWithEvent(): Promise<response<CheckOutAutomationWithEvent>> {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}/with-event`;
  const response: response<CheckOutAutomationWithEvent> = await axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return response;
}

export async function deleteCheckOutAutomation(
  checkOutAutomationId: number | undefined,
): Promise<response<CheckOutAutomation>> {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}/${checkOutAutomationId}`;
  const response: response<CheckOutAutomation> = await axios.delete(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return response;
}

export async function getCheckOutAutomation(checkOutAutomationId: number): Promise<response<CheckoutAutomationType>> {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}/${checkOutAutomationId}`;
  const response: response<CheckoutAutomationType> = await axios.get(url, {
    headers: { ...(await getAuthHeader()) },
  });

  return response;
}

export async function updateCheckOutAutomation(
  checkOutAutomationId: number,
  params: UpdateCheckOutAutomationRequestParam,
) {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}/update/${checkOutAutomationId}`;
  await axios.post(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
}

export async function mapCheckOutAutomationToEvents(params: MapCheckOutAutomationToEvents) {
  const orgId = getSelectedOrgId();
  const url = `/api/workflow/checkout-automation/${orgId}/map-events`;
  await axios.post(url, params, {
    headers: { ...(await getAuthHeader()) },
  });
}
